<template>
  <div class="background-order">
    <b-container>
      <b-breadcrumb class="title-page">
        <b-breadcrumb-item class="size-breadcrumb" active
          ><a href="#/my-order">
            {{ $t('Myorder.header') }}
          </a></b-breadcrumb-item
        >
        <b-breadcrumb-item class="size-breadcrumb text-info">{{
          $t('OrderDetail.header')
        }}</b-breadcrumb-item>
      </b-breadcrumb>
      <b-row v-show="info">
        <div class="w-100 mb-5">
          <b-card class="p-md-3">
            <b-media class="mt-5">
              <template #aside>
                <h2 class="text-info mr-5">{{ formatStatus(info.status) }}</h2>
              </template>
              <b-row>
                <b-col cols="12" lg="5"></b-col>
                <b-col cols="12" md="6" lg="4">
                  <div>
                    <span>{{ $t('OrderDetail.total_amount') }}：</span>
                    <span class="span-amount text-danger"
                      >${{ info.grandTotal }}</span
                    >
                  </div>
                  <div>
                    <span>{{ $t('OrderDetail.total_paid') }}：</span>
                    <span class="span-amount text-danger"
                      >${{ info.paid ? info.paid : '0' }}</span
                    >
                  </div>
                  <div>
                    <span>{{ $t('OrderDetail.total_remaining') }}：</span>
                    <span class="span-amount text-danger"
                      >${{
                        info.paid
                          ? info.grandTotal - info.paid
                          : info.grandTotal
                      }}</span
                    >
                  </div>
                </b-col>
                <b-col cols="12" md="3">
                  <button
                    class="view-more"
                    v-if="
                      info.status == 0 ||
                      info.status == -1 ||
                      ((info.status == 2 || info.status == 1) &&
                        info.payments != null &&
                        info.grandTotal != 0 &&
                        this.paymentLength != 0)
                    "
                    href="javascript:void(0);"
                    v-on:click="openInvoice()"
                  >
                    {{ $t('OrderDetail.dl_invoices') }}
                  </button>
                </b-col>
              </b-row>
            </b-media>
            <b-row class="pl-5 pt-5 pb-3">
              <b-col cols="12" md="4"
                >{{ $t('Myorder.order_number') }}：<span class="text-info">{{
                  info.orderNumber
                }}</span></b-col
              >
              <b-col cols="12" md="4"
                >{{ $t('Myorder.order_time') }}：{{ info.createdAt }}</b-col
              >
            </b-row>
            <b-jumbotron
              lead-tag="div"
              bg-variant="transparent"
              text-variant="black"
              border-variant="light"
              class="card ml-md-3 pt-4 mb-5"
              header-tag="h4"
              header-level="4"
            >
              <template #header>{{
                $t('OrderDetail.contact_information')
              }}</template>
              <template #lead>
                <div class="text-size">
                  <div class="pt-4">
                    {{ $t('OrderDetail.name') }}：{{
                      info.contactTitle +
                      ' ' +
                      info.contactSurname +
                      ' ' +
                      info.contactGivenName
                    }}
                  </div>
                  <div class="pt-3">
                    {{ $t('OrderDetail.phone') }}：{{ info.contactPhone }}
                  </div>
                  <div class="pt-3">
                    {{ $t('OrderDetail.Contact_mail') }}：{{
                      info.contactEmail
                    }}
                  </div>
                  <div class="pt-3">
                    {{ $t('OrderDetail.Other_remarks') }}：{{
                      info.otherRemarks
                    }}
                  </div>
                </div>
              </template>
            </b-jumbotron>
            <b-jumbotron
              lead-tag="div"
              bg-variant="transparent"
              text-variant="black"
              border-variant="light"
              class="card ml-md-3 pt-4 mb-5"
              header-tag="h4"
              header-level="4"
            >
              <template #header>{{
                $t('OrderDetail.passenger_information')
              }}</template>
              <template #lead>
                <div v-for="(value, index) in info.members" :key="index">
                  <h6 class="text-info pt-4">
                    {{ $t('OrderDetail.passenger') }} {{ index + 1 }}
                  </h6>
                  <b-row class="text-size text-dark py-2">
                    <b-col cols="12" md="4"
                      >{{ $t('booking.Ch_En') }}：{{
                        value.titleEngName +
                        ' ' +
                        value.surnameEng +
                        ' ' +
                        value.givenNameEng
                      }}</b-col
                    >
                    <b-col cols="12" md="4"
                      >{{ $t('booking.Name_booking') }}：{{
                        value.titleChinaName +
                        ' ' +
                        value.surnameChina +
                        ' ' +
                        value.givenNameChina
                      }}</b-col
                    >
                    <b-col cols="12" md="3"
                      >{{ $t('OrderDetail.birthday') }}：{{
                        formatDate(value.birthday)
                      }}</b-col
                    >
                  </b-row>
                  <b-row class="text-size text-dark">
                    <b-col cols="12" md="4"
                      >{{ $t('OrderDetail.phone') }}：{{
                        value.prefixPhone + ' ' + value.contact
                      }}</b-col
                    >
                    <b-col cols="12" md="4"
                      >{{ $t('OrderDetail.email') }}：{{ value.email }}</b-col
                    >
                    <b-col cols="12" md="3"
                      >{{ $t('OrderDetail.passport_number') }}：{{
                        value.passportNumber
                      }}</b-col
                    >
                  </b-row>
                </div>
              </template>
            </b-jumbotron>
            <b-row class="text-center">
              <b-button
                class="m-auto"
                size="md"
                variant="warning"
                href="#/my-order"
                >{{ $t('register.return') }}</b-button
              >
            </b-row>
          </b-card>
        </div>
      </b-row>
      <div v-if="!info" class="w-100">
        <b-container>
          <Spinner />
        </b-container>
      </div>
    </b-container>
    <b-modal
      ref="modal-show-invoice"
      id="modal-show-invoice"
      :hide-footer="true"
    >
      <div
        class="row card shadow-none border-0 pb-5 mb-3 card-body px-lg-5 py-lg-3 modal-from-tour"
      >
        <p class="my-4 text-center w-100">
          {{ $t('register.Please_enter_payment_amount') }}
        </p>
        <form role="form" class="px-5 pt-4 modal-form">
          <b-form-group class="paymentAmountCustomer-group">
            <b-input-group>
              <b-form-input
                :placeholder="$t('register.Please_enter_payment_amount')"
                v-model="paymentAmountCustomer"
              ></b-form-input>
            </b-input-group>
            <span class="error"></span>
          </b-form-group>
        </form>
      </div>
      <hr />
      <div class="row text-center">
        <div class="col-6">
          <b-button
            variant="secondary"
            @click="$bvModal.hide('modal-cancel-bk')"
            >{{ $t('Myorder.cancel') }}</b-button
          >
        </div>
        <div class="col-6">
          <b-button variant="warning" @click="submitGopay()"
            >{{ $t('Myorder.go_pay') }}
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-modal ref="modal-invoice-bk" id="modal-invoice-bk" :hide-footer="true">
      <div class="row">
        <div
          class="col-sm-6 mb-2"
          v-for="(value, index) in info.payments"
          :key="index"
        >
          <a
            :href="baseUrl + '#/' + invoiceLink + '/' + value.paymentId"
            target="_blank"
          >
            <img
              src="@/assets/images/pdf.png"
              style="width: 70px"
              alt="Image"
            />
            <span>{{ value.paymentNumber }}</span>
          </a>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import config from '@/app.config';
import Spinner from '../spinner/Spinner';
import moment from 'moment';

export default {
  components: {
    Spinner,
  },
  data() {
    return {
      info: '',
      invoiceLink: 'invoices',
      paymentLength: 0,
    };
  },
  computed: {
    baseUrl() {
      return location.origin + location.pathname;
    },
  },
  created() {
    var id = this.$route.params.order_id;
    this.$store
      .dispatch('orderModule/getPaymentsByOrderId', {
        id: id,
      })
      .then((data) => {
        this.info = data;
        paymentLength = this.info.payments.length;
        this.info.createdAt = moment(String(this.info.createdAt)).format(
          'YYYY-MM-DD hh:mm:ss',
        );
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    formatDate: function (value) {
      value = moment(String(value)).format('YYYY-MM-DD');
      return value;
    },
    openInvoice() {
      if (this.info.payments.length == 1) {
        // window.open(
        //   this.baseUrl +
        //     '/' +
        //     this.invoiceLink +
        //     '/' +
        //     this.info.payments[0].paymentId,
        //   '_blank',
        // );
        window.open(
          `${this.baseUrl}#/invoices/${this.info.payments[0].paymentId}`,
        );
      } else {
        this.$refs['modal-invoice-bk'].show();
      }
    },
    formatGender: function (value) {
      if (value === 'F') return this.$t('OrderDetail.female');
      else return this.$t('OrderDetail.male');
    },
    // DEPOSIT = -1;
    // FULLPAID = 0;
    // INCOMPLETE = 1;
    // CANCEL = 2;
    // CLOSE = 3;
    formatStatus: function (value) {
      if (value == 0) return this.$t('Myorder.paid');
      if (value == -1) return this.$t('Myorder.partial');
      if (value == 1) return this.$t('Myorder.unpaid');
      else return this.$t('Myorder.cancel');
    },
  },
};
</script>

<style type="text/css" scoped>
.text-size {
  font-size: 0.95rem;
}

.background-order {
  background-color: #f3f6f8;
}

.breadcrumb-item + .breadcrumb-item::before {
  color: #000000;
  content: '>';
}

.span-amount {
  font-size: 1.425rem;
}

.size-breadcrumb {
  font-weight: 500;
  font-size: 1.6625rem;
}

.title-page {
  height: 70px;
  padding-top: 32px;
}
#modal-invoice-bk .modal-content {
  border-radius: unset;
  border: unset;
}
button.view-more {
  background-color: #f6a604;
  color: white;
  padding: 0.5rem 2rem;
  border: 1px solid #f6a604;
  border-radius: 4px !important;
}

button.view-more:hover {
  border: 1px solid #f6a604;
  background-color: white;
  color: black;
}
</style>
